import Loadable from 'react-loadable';
import Loading from 'components/Loading';

export const SurveyRouter = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Survey/Survey.container'),
});

export const PreSurvey = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/PreTD/PreTD.container'),
});

export const PostSurvey = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/PostTD/PostTD.container'),
});

export const BasicSurvey = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Basic/Basic.container'),
});

export const RsvpSurvey = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/RSVP/Rsvp.container'),
});

export const CheckinSurvey = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/CheckIn/CheckIn.container'),
});

export const Training = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Training/Training.container'),
});

export const Dealer = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Dealer/Dealer.container'),
});

export const Signin = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Signin/Signin.container'),
});

export const Signout = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Signout/Signout.container'),
});

export const Demo = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Demo/Signin/DemoSignin.container'),
});

export const CreateEvent = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Demo/CreateEvent/CreateEvent.container'),
});

export const ThankYou = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/ThankYou/ThankYou.page'),
});

export const Unsubscribe = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Unsubscribe/Unsubscribe.page'),
});


export const NotFound = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/NotFound/NotFound.page'),
});

export const Canceled = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Canceled/Canceled.page'),
});

export const Error = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Error/Error.container'),
});

export const AutoLogin = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/AutoLogin/AutoLogin.container'),
});

export const MagicLogin = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/MagicLogin/MagicLogin.container'),
});

export const TryAgain = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/TryAgain/TryAgain.page'),
});

export const Postponed = Loadable({
  loading: Loading,
  timeout: 10000,
  loader: () => import('pages/Postponed/Postponed.page'),
});
