import {
  setSeason,
  verifyAuth,
  setGlobalSettings,
} from './';

import uuid from 'uuid';

export function checkEventId(hashed_url) {
  return function(dispatch, API) {
    return API.event.getEvent({ hashed_url })
      .then(response => {
        const formattedSettings = {};
        response.data.settings.map(o => {
          formattedSettings[o.setting_name] = o.setting_value;
        });
        dispatch(setSeason(response.data.data.season_id));
        dispatch(setGlobalSettings(formattedSettings));
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function saveLCCPost(device_survey_guid, opinion) {
  return function(dispatch, API) {
    console.log('saveLCCPost', { device_survey_guid, opinion });
    return API.event.saveLCCPostAnswer({ device_survey_guid, opinion })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function unsubscribeFromEmails(email) {
  return function(dispatch, API) {
    console.log('unsubscribeFromEmails', { email });
    return API.event.unsubscribe({ email })
      .then(response => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function updateLCCSurvey(device_survey_guid, impression, in_market_timing, followup_survey_opt_in, vehicles_of_interest, access_key) {
  return function(dispatch, API) {
    return API.event.updateLCCSurvey({ device_survey_guid, impression, in_market_timing, followup_survey_opt_in })
      .then(async response => {
        // this probably needs some refactoring/error handling work
        try {
          const vehicleOfInterestData = vehicles_of_interest.map((vehicle => {
            return {
              vehicle_id: vehicle,
              survey_vehicle_guid: uuid.v1(),
              device_survey_guid,
            };
          }));
          const submitRequests = [
            vehicleOfInterestData && vehicleOfInterestData.length && API.survey.submitVehiclesOfInterest(vehicleOfInterestData, access_key),
          ];
          Promise.all(submitRequests)
            .then(() => {
              return Promise.resolve();
            })
            .catch(error => {
              return Promise.reject(error);
            });
        } catch (e) {
          return Promise.reject(e);
        }
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function endEvent() {
  return function(dispatch, API) {
    return API.event.end()
      .then(() => {
        return dispatch(verifyAuth());
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

export function clickedDemo(eventId) {
  return function(dispatch, API) {
    return API.event.clickedDemo({ eventId })
      .then(() => {
        return new Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}
