import nap from 'utils/nap';

import {
  SET_CHECK_OUT_DATA,
  TOGGLE_CHECKED_OUT,
} from '../types';

import {
  setCheckOutDataLoading,
  setCheckedOutSubmitting,
} from './';

export function checkOut(survey) {
  return function(dispatch, API, getState) {
    const { event_id } = getState().event;

    const {
      survey_id,
      device_survey_guid,
      driven_vehicles: _driven_vehicles,
      initials,
    } = survey;

    const driven_vehicles = _driven_vehicles.map((vehicle => {
      return {
        device_survey_guid,
        vehicle_id: vehicle,
      };
    }));

    dispatch(setCheckedOutSubmitting(survey_id, true));

    return nap(300, API.check.out({ survey_id, event_id, initials, driven_vehicles }))
      .then(() => {
        dispatch({
          type: TOGGLE_CHECKED_OUT,
          payload: {
            ...survey,
            checked_out: true,
          },
        });

        return Promise.resolve(true);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}


export function checkOutData(trackLoading = true) {
  return function(dispatch, API, getState) {
    const { event_id } = getState().event;

    const time = trackLoading ? 400 : 0;

    trackLoading && dispatch(setCheckOutDataLoading(true));

    return nap(time, API.check.out_data({ event_id }))
      .then(response => {
        dispatch({
          type: SET_CHECK_OUT_DATA,
          payload: response.data,
        });

        trackLoading && dispatch(setCheckOutDataLoading(false));
        return Promise.resolve(true);
      })
      .catch((error) => {
        trackLoading && dispatch(setCheckOutDataLoading(false));
        return Promise.reject(error);
      });
  };
}


export function clearCheckOutData() {
  return {
    type: SET_CHECK_OUT_DATA,
    payload: {},
  };
}
