import React from 'react';
import AuthHawk from 'HOC/AuthHawk';

import {
  Switch,
  Route,
} from 'react-router-dom';

import {
  Signin,
  Signout,
  Training,
  Dealer,
  SurveyRouter,
  PreSurvey,
  PostSurvey,
  BasicSurvey,
  RsvpSurvey,
  CheckinSurvey,
  ThankYou,
  NotFound,
  Error,
  Canceled,
  Unsubscribe,
  AutoLogin,
  TryAgain,
  MagicLogin,
  // Demo,
  CreateEvent,
  // Postponed,
} from './lazy_routes';

export default (
  <Switch>

    {/* <Route*/}
    {/*  path="/"*/}
    {/*  component={Postponed}*/}
    {/* />*/}

    <Route
      exact
      path="/"
      // component={process.env.NODE_ENV === 'demo' ? Demo : Signin}
      component={Signin}
    />

    <Route
      path="/training"
      // component={Training}
      component={AuthHawk(Training)}
    />

    <Route
      path="/event"
      component={AuthHawk(Dealer)}
    />

    <Route
      exact
      path="/signout"
      component={Signout}
    />

    <Route
      exact
      path="/create"
      component={CreateEvent}
    />

    <Route
      exact
      path="/auto_login/:token?"
      component={AutoLogin}
    />

    <Route
      exact
      path="/m/:hash/:path?"
      component={MagicLogin}
    />

    <Route
      exact
      path="/submit/success/:event_portal?"
      component={ThankYou}
    />

    <Route
      exact
      path="/submit/error/:event_portal?"
      component={Error}
    />

    <Route
      exact
      path="/canceled"
      component={Canceled}
    />

    <Route
      exact
      path="/unsubscribe/:invite_email?"
      component={Unsubscribe}
    />

    <Route
      exact
      path="/pre/:hashed_url/:event_portal?"
      component={PreSurvey}
    />

    <Route
      exact
      path="/post/:hashed_url/:event_portal?"
      component={PostSurvey}
    />

    <Route
      exact
      path="/basic/:hashed_url/:event_portal?"
      component={BasicSurvey}
    />

    <Route
      exact
      path="/rsvp/:hashed_url"
      component={RsvpSurvey}
    />

    <Route
      exact
      path="/check-in/:hashed_url/:device_survey_guid?"
      component={CheckinSurvey}
    />

    <Route
      exact
      path="/retry/:event_portal?"
      component={TryAgain}
    />

    <Route
      exact
      path="/:hashed_url/:event_portal?"
      component={SurveyRouter}
    />

    <Route component={NotFound} />

  </Switch>
);
