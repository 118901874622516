import React, { Component } from 'react';
import { Detector, Offline } from 'react-detect-offline';

import L from 'common/get-localized-string';
import Routes from './routes';
import style from './_Main.module.scss';
const  iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
if (iOS) {
  document.head.querySelector('meta[name="viewport"]').content = 'width=device-width, initial-scale=1, maximum-scale=1';
}

function ConnectionStatus(props) {
  if (props.disabled) return null;

  const language = window.navigator.language.substr(0, 2);
  const deviceOfflineWarning = L('deviceOfflineWarning', language);
  return (
    <div>
      <Detector
        polling={
          {
            enabled: true,
            url: 'https://api.latitudeshowtracker.com',
          }
        }
        render={({ online }) => {
          return (
            <p>
              {online
                ? null
                : alert(deviceOfflineWarning)
              }
            </p>
          ); }}
      />
      <Offline>
        <p
          style={{ color: 'red', marginLeft: '20px' }}
        >
          {L('deviceOffline', language)}<br/>
                    1. {L('checkDeviceConnection', language)}<br/>
                    2. {L('checkThat', language)} <a href="https://api.latitudeshowtracker.com/verify" target="_blank">https://api.latitudeshowtracker.com/verify</a> {L('and', language)} <a href="https://d4ur.com" target="_blank">https://d4ur.com</a> {L('availableOnNetwork', language)}<br/>
                    3. {L('contactSupport', language)}
        </p>
      </Offline>
    </div>
  );
}

function DemoWarning() {
  if (process.env.NODE_ENV !== 'demo') return null;

  return (

    <div className={style.container}>
      <span className={style.warning}>
                    WARNING: DEMO MODE
      </span>
      <p>
                    Do not use for real events, data will not be recorded.
      </p>
      <p>
                    Please visit <a href="https://d4ur.com">d4ur.com</a> for actual events.
      </p>
    </div>

  );
}

class Main extends Component {
  render() {
    return (
      <main >

        <ConnectionStatus disabled />
        <DemoWarning />

        { Routes }

      </main>
    );
  }
}

export default Main;
