import {
  SET_SUBMITTING,
  SET_CHECK_IN_DATA,
  SET_CHECK_OUT_DATA,
} from '../types';

const INITIAL_STATE = {
  pga_check_in: {},
  checked_out: {},
  training_email: false,
  guest_invites: false,
};

const INITIAL_ACTION = { type: '' };

export default function(state = INITIAL_STATE, action = INITIAL_ACTION) {
  switch (action.type) {
    case SET_SUBMITTING:
      return {
        ...state,
        ...action.payload,
      };

    case SET_CHECK_IN_DATA: {
      return {
        ...state,
        pga_check_in: {},
      };
    }

    case SET_CHECK_OUT_DATA: {
      return {
        ...state,
        checked_out: {},
      };
    }

    default:
      return state;
  }
}
