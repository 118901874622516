import ApiGroup from './ApiGroup';

export default class surveyAPI extends ApiGroup {
  constructor() {
    super({
      name: 'survey',
    });
  }

    submit = (data, access_key) => {
      return this.post({
        endpoint: `/insert/v1`,
        data,
      },
      {
        headers: {
          'authorization': access_key,
        },
      });
    };

  submitVehiclesOfInterest = (data, access_key) => {
    this.post({
      endpoint: '/insert/vehicles/interested',
      data,
    },
    {
      headers: {
        'authorization': access_key,
      },
    });
  };

  submitVehiclesDriven = (data, access_key) => {
    this.post({
      endpoint: '/insert/vehicles/driven',
      data,
    },
    {
      headers: {
        'authorization': access_key,
      },
    });
  };

    postDriveSurveyLink = (data) => {
      this.post({
        endpoint: '/post/drive/link',
        data,
      });
    };

    postDriveLinkClick = (data) => {
      return this.post({
        endpoint: '/post/drive/link/click',
        data,
      });
    };

    bonusCompleted = (data) => {
      return this.post({
        endpoint: '/bonus/completed',
        data,
      });
    };

    phoneVerification = (data) => {
      return this.post({
        endpoint: '/verify/phone',
        data,
      });
    };
}
